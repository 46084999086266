//COMPONENT
import React, { useState } from 'react'
import styled from 'styled-components'
import { Carousel } from 'antd'
//COMPONENT END

//IMGS
import ImgLogo from '../../assets/imgs/logos/JC_LOGO.png'
import Maps from '../../assets/imgs/logos/maps.png'
//IMGS END

//ICONS
import { Propaganda } from '../../components/fundoPropaganda'
import { MdOutlineWhatsapp } from 'react-icons/md'
import { FaInstagram } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'

//ICONS END

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  height: '300px',
  color: '#000000',
  lineHeight: '300px',
  textAlign: 'center',
  background: 'linear-gradient(-35deg, #2884f1, #91c6ec, #2884f1)',
  margin: '0'
}

export const TelaInicio = () => {
  const [showContact, setShowContact] = useState(false)

  return (
    <Container>
      <SubMenu>
        <h3>(62) 99155-5042</h3>
        <h3>teste.teste@gmail.com</h3>
      </SubMenu>

      <MenuPesquisa>
        <DivLogo>
          <ImageLogo src={ImgLogo} />
        </DivLogo>
        <StyledLink
          href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20conhecer%20melhor%20os%20servi%C3%A7os%20oferecidos%20pela%20JC%20e%20gostaria%20de%20agendar%20um%20or%C3%A7amento."
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonCarrinho
            onMouseEnter={() => setShowContact(true)}
            onMouseLeave={() => setShowContact(false)}
          >
            {showContact ? (
              <>
                <TextWhat>WhatsApp</TextWhat>
                <MdOutlineWhatsapp />
              </>
            ) : (
              <>
                <TextWhat>Fale conosco</TextWhat>
                <MdOutlineWhatsapp />
              </>
            )}
          </ButtonCarrinho>
        </StyledLink>
      </MenuPesquisa>

      <Propaganda />
      <MenuLinks>
        <DivLink>
          <StyledLink
            href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20conhecer%20melhor%20os%20servi%C3%A7os%20oferecidos%20pela%20JC%20e%20gostaria%20de%20agendar%20um%20or%C3%A7amento."
            target="_blank"
            rel="noopener noreferrer"
          >
            <TextForm>SOLICITE UM ORÇAMENTO</TextForm>
          </StyledLink>
        </DivLink>
        <DivLink>
          <StyledLink
            href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20encaminhar%20meu%20curr%C3%ADculo%20e%20ser%20um%20novo%20colaborador%20da%20%20JC%20."
            target="_blank"
            rel="noopener noreferrer"
          >
            <TextForm>TRABALHE CONOSCO</TextForm>
          </StyledLink>
        </DivLink>
        <DivLink>
          <StyledLink
            href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20conhecer%20melhor%20os%20servi%C3%A7os%20oferecidos%20pela%20JC%20e%20gostaria%20de%20agendar%20um%20or%C3%A7amento."
            target="_blank"
            rel="noopener noreferrer"
          >
            <TextForm>SEJA UM FRANQUEADO</TextForm>
          </StyledLink>
        </DivLink>
      </MenuLinks>

      <CelularesDiv>
        <h3>Escolha o veículo que mais adere a sua necessidade</h3>
        <InternaDiv>
          <CelularesSubDiv>
            <CelularImg src="https://production.autoforce.com/uploads/consortium/avatar/4833/model_main_webp_comprar-fiorino-endurance-1-4-flex-2023_9ae6af110e.png.webp" />
            <NameCelText>Fiorino</NameCelText>
            <StyledLink
              href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20conhecer%20melhor%20os%20servi%C3%A7os%20oferecidos%20pela%20JC%20e%20gostaria%20de%20agendar%20um%20or%C3%A7amento."
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonComprarText>Solicitar orçamento</ButtonComprarText>
            </StyledLink>
          </CelularesSubDiv>

          <CelularesSubDiv>
            <CelularImg src="https://production.autoforce.com/uploads/picture/image/156174569/main_webp_comprar-cg-160-fan-2023_845994c645.jpg.webp" />
            <NameCelText>Motocicleta</NameCelText>
            <StyledLink
              href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20conhecer%20melhor%20os%20servi%C3%A7os%20oferecidos%20pela%20JC%20e%20gostaria%20de%20agendar%20um%20or%C3%A7amento."
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonComprarText>Solicitar orçamento</ButtonComprarText>
            </StyledLink>
          </CelularesSubDiv>

          <CelularesSubDiv>
            <CelularImg src="https://dezeroacem.com.br/wp-content/uploads/2016/08/Fiat-Palio-Sporting-2017.jpg" />
            <NameCelText>Carro</NameCelText>
            <StyledLink
              href="https://api.whatsapp.com/send?phone=5562991555042&text=Ol%C3%A1!%20Estou%20interessado%20em%20conhecer%20melhor%20os%20servi%C3%A7os%20oferecidos%20pela%20JC%20e%20gostaria%20de%20agendar%20um%20or%C3%A7amento."
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonComprarText>Solicitar orçamento</ButtonComprarText>
            </StyledLink>
          </CelularesSubDiv>
        </InternaDiv>
      </CelularesDiv>
      <Carousel autoplay autoplaySpeed={3000}>
        <CarouselDiv>
          <TextCarousel style={contentStyle}>A 15 anos no mercado</TextCarousel>
        </CarouselDiv>
        <CarouselDiv>
          <TextCarousel style={contentStyle}>
            Mais de 12 clientes fixos
          </TextCarousel>
        </CarouselDiv>
        <CarouselDiv>
          <TextCarousel style={contentStyle}>
            Segurança e conforto para sua empresa
          </TextCarousel>
        </CarouselDiv>
      </Carousel>
      <ImgsDiv src="https://www.allteensexpress.com.br/thumb.php?w=1024&h=400&src=images/1529309063.png" />
      <ImgsDiv src={Maps} />
      <SocialDiv>
        <TextFim>JC TRANSPORTE DE MATERIAIS BIOLÓGICOS</TextFim>
        <LinkDiv>
          <StyledLink
            href="https://www.instagram.com/jc_transportebiologico?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </StyledLink>
          <StyledLink
            href="https://www.instagram.com/jc_transportebiologico?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdLocationOn />
          </StyledLink>
        </LinkDiv>
      </SocialDiv>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const SubMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  height: 50px;
  background: white;
  padding: 10px 40px;
  border-bottom: 1px solid black;

  @media (max-width: 768px) {
    font-size: 12px;
    justify-content: center;
  }
`
const DivLogo = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const TextForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  gap: 10px;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }
`

const ButtonCarrinho = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
  background: linear-gradient(-35deg, #2884f1, #91c6ec, #2884f1);
  border-radius: 4px;
  gap: 6px;

  &:hover {
    background: #0fd7b0 linear-gradient(-35deg, #0fd7b0, #85eed9, #28c4a4);
  }

  svg {
    width: 40px;
    height: 20px;
    fill: #000000;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 40px;
  }
`
const TextWhat = styled.h3`
  @media (max-width: 768px) {
    display: none;
  }
`

const ImageLogo = styled.img`
  width: 250px;
  height: 50px;

  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
  }
`

const MenuPesquisa = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  background: white;
  padding: 20px 40px;
  gap: 10px;
`
const MenuLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  gap: 15px;
  background: #2884f1;
  padding: 10px 20px;
`

const DivLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background: transparent;
  padding: 0px 10px;
  border-right: 1px solid #ffffff;
  cursor: pointer;

  ${TextForm} {
    &:hover {
      color: #000000;
    }
  }
`
const CarouselDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
    fill: #000000;
  }
`
const TextCarousel = styled.div`
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const CelularesDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: white;
  color: #000000;
  gap: 20px;
  padding: 15px 20px;
`
const CelularesSubDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  border: 1px solid #000000;
  padding: 15px;
  gap: 12px;
`
const InternaDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const CelularImg = styled.img`
  background: #ffff;
  width: 100%;
  height: 200px;
`
const ButtonComprarText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  text-align: center;
  border: 1px solid #000000;
  font-size: 16px;
  color: #000000;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(-35deg, #2884f1, #91c6ec, #2884f1);

  &:hover {
    background: #0fd7b0 linear-gradient(-35deg, #0fd7b0, #85eed9, #28c4a4);
    color: #000000;
  }
`

const ImgsDiv = styled.img`
  width: 100%;
  border-bottom: 1px solid #000000;
`

const SocialDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  gap: 20px;
  padding: 20px 40px;
  background: #2884f1;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
  }
`

const TextFim = styled.h3`
  font-size: 12px;
  color: #ffffff;
`
const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

//Text

const NameCelText = styled.h1`
  width: 100%;
  height: 30px;
  font-size: 18px;
  background: white;
  color: black;
  margin: 0;
`
const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`
